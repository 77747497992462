import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';


enum Quadrants {
  PROCESS = 'process',
  FRAMEWORKS = 'frameworks',
  INFRASTRUCTURE = 'infrastructure',
  LANGUAGES = 'languages',
}

const entries = new Array<RadarEntry>();

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2022-01-01'),
    },
  ],
  url: '#',
  key: 'kotlin',
  id: 'kotlin',
  title: 'Kotlin',
  quadrant: Quadrants.LANGUAGES,
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2022-01-01'),
    },
  ],
  url: '#',
  key: 'spock',
  id: 'spock',
  title: 'Spock',
  quadrant: Quadrants.FRAMEWORKS,
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2022-01-01'),
    },
  ],
  url: '#',
  key: 'micronaut',
  id: 'micronaut',
  title: 'Micronaut',
  quadrant: Quadrants.FRAMEWORKS,
});

entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2023-01-01'),
    },
  ],
  url: '#',
  key: 'pair-programming',
  id: 'pair-programming',
  title: 'pair-programming',
  quadrant: Quadrants.PROCESS,
});

const rings = new Array<RadarRing>();
rings.push({ id: 'adopt', name: 'ADOPT', color: '#93c47d' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#93d2c2' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#fbdb84' });
rings.push({ id: 'hold', name: 'HOLD', color: '#efafa9' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: Quadrants.INFRASTRUCTURE, name: 'Infrastructure' });
quadrants.push({ id: Quadrants.FRAMEWORKS, name: 'Frameworks' });
quadrants.push({ id: Quadrants.LANGUAGES, name: 'Languages' });
quadrants.push({ id: Quadrants.PROCESS, name: 'Process' });

export const radar: TechRadarLoaderResponse = {
  entries,
  quadrants,
  rings,
};


export class PlatformTechRadarClient implements TechRadarApi {
  async load(): Promise<TechRadarLoaderResponse> {
    return radar;
  }
}
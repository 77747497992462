import {
    ScmIntegrationsApi,
    scmIntegrationsApiRef,
    ScmAuth,
} from '@backstage/integration-react';
import {
    AnyApiFactory, ApiRef, BackstageIdentityApi,
    configApiRef,
    createApiFactory, createApiRef, discoveryApiRef, OAuthApi, oauthRequestApiRef, ProfileInfoApi, SessionApi,
} from '@backstage/core-plugin-api';
import {OAuth2} from "@backstage/core-app-api";
import {techRadarApiRef} from "@backstage/plugin-tech-radar";
import {PlatformTechRadarClient} from './PlatformTechRadar'

export const auth0AuthApiRef: ApiRef<OAuthApi &
    ProfileInfoApi &
    BackstageIdentityApi &
    SessionApi> = createApiRef({
    id: 'internal.auth.auth0',
});

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    createApiFactory({
        api: auth0AuthApiRef,
        deps: {
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
            configApi: configApiRef,
        },
        factory: ({discoveryApi, oauthRequestApi, configApi}) =>
            OAuth2.create({
                discoveryApi,
                oauthRequestApi,
                provider: {
                    id: 'auth0',
                    title: 'Auth0 Auth Provider',
                    icon: () => null,
                },
                environment: configApi.getOptionalString('auth.environment'),
                defaultScopes: [
                    'openid',
                    'profile',
                    'email',
                    'offline_access'
                ],
                configApi,
            }),
    }),
    ScmAuth.createDefaultApiFactory(),
    createApiFactory(techRadarApiRef, new PlatformTechRadarClient()),
];
